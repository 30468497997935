<mat-toolbar>

    <div>
        <button mat-icon-button (click)="backToList()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div class="title">
            <mat-icon>{{GLOBAL.ICONS.STAT}}</mat-icon>
            <ng-container>
                STATS
            </ng-container>
        </div>
    </div>
    <div>
        {{months[month]}} {{year}} {{year ? '-' : ''}} {{uniqueStat}} {{uniqueStat ?
        (sortOptionsForm.controls['uniqueStatPerc'].value ? ' % -' : ' - ') : ''}}
        {{sortOptionsForm.controls['statReport'].value | param:'statReports'}}
    </div>
    <div>
        <button *ngIf="!chart" mat-icon-button (click)="viewchart()">
            <mat-icon>pie_chart</mat-icon>
        </button>
        <profile></profile>
    </div>

</mat-toolbar>
<div class="toolbartop"></div>

<div class="main-container-flex split s20">
    <div class="main">
        <ng-container *ngIf="chart">
            <ngx-charts-advanced-pie-chart [results]="statService.statChartPie">
            </ngx-charts-advanced-pie-chart>
        </ng-container>
        <ngx-charts-bar-vertical-stacked *ngIf="!chart" [results]="statService.statChart" [gradient]="gradient"
            [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
            [animations]="animations" [customColors]="customColors" (select)="onSelectBar($event)">
        </ngx-charts-bar-vertical-stacked>
    </div>
    <div class="side">
        <form [formGroup]="sortLocalForm" novalidate>

            <mat-form-field appearance="fill">
                <mat-label>Agency</mat-label>
                <mat-select formControlName="agency">
                    <mat-option value="">
                        All Agencies
                    </mat-option>
                    <mat-option *ngFor="let param of 'userAgencies' | params" [value]="param.id">
                        {{param.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Realtor</mat-label>
                <mat-select formControlName="realtor">
                    <mat-option value="">
                        All Agents
                    </mat-option>
                    <mat-option *ngFor="let param of setting.realtorsAgency[sortLocalForm.controls['agency'].value]"
                        [value]="param.id">
                        {{param.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>


        <form [formGroup]="sortOptionsForm" novalidate>
            <mat-form-field appearance="fill">
                <mat-label>Reports</mat-label>
                <mat-select formControlName="statReport">
                    <mat-option *ngFor="let param of 'statReports' | params" [value]="param.id">
                        {{param.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill"
                *ngIf="statService.statConfs[sortOptionsForm.controls['statReport'].value].mandateType">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type">
                    <mat-option [value]="null">
                        No Selection
                    </mat-option>
                    <mat-option value="sale">
                        Sale
                    </mat-option>
                    <mat-option value="rental">
                        Rental
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" *ngIf="uniqueStat">
                <mat-slide-toggle formControlName="uniqueStatPerc">% Percentage
                </mat-slide-toggle>
                <textarea matInput hidden></textarea>
            </mat-form-field>
        </form>
    </div>
</div>
<!-- <table mat-table [dataSource]="statService.statList" class="main">

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element"> {{element.date}} </td>
    </ng-container>

    <ng-container *ngFor="let col of statService.statCols[groupStat]" [matColumnDef]="col.id">
        <th mat-header-cell *matHeaderCellDef>{{col.name}}</th>
        <td mat-cell *matCellDef="let element">
            {{element[col.id]}}
        </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="tableCols; sticky: true">
    </tr>
    <tr mat-row *matRowDef="let row; let element; columns: tableCols;">
    </tr>
</table> -->