import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MainComponent } from 'src/common/pages/main/main.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { MainItem } from 'src/common/services/main/main.model';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { Action } from 'src/services/action/action.model';
import { ActionService } from 'src/services/action/action.service';
import { ActionOffer } from 'src/services/action/actions/action-offer.model';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionComponent extends MainComponent {

  @Input() moduleItems: MainItem[];

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ActionService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public backURL: BackurlService,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Action(null, setting);
  }

  ngOnInit(): void { // Simple First init of the action for the next pages

    if (this._$key == '0') {
      this._$key = this.route.snapshot.paramMap.get('id');
    }

    if (this._$key && this._$key != '0') {
      this.new = false;
      this.db.getData(this._$key).then(data => {
        this.item.fromFB(data);
        this._cd.detectChanges();
      });

    }
  }
}
