<mat-toolbar class="residencesbg">

    <div>
        <button mat-icon-button *ngIf="!pageConfig.edit" (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <a mat-icon-button (click)="displayItem()" *ngIf="pageConfig.edit">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <div class="title">
            <mat-icon>{{GLOBAL.ICONS.RESIDENCE}}</mat-icon>
            <ng-container *ngIf="!new">
                R{{item.values.ref}}:
                {{item.values.name}}
                <ng-container *ngIf="item.values.scheme"> - {{item.values.scheme | param : 'residenceSchemes'}}
                </ng-container>
                - {{item.values.address?.eCity | param : 'cities'}}

                <ng-container *ngIf="item.values.status"> - {{item.values.status | param : 'residenceStatus'}}
                </ng-container>
            </ng-container>
            <ng-container *ngIf="new">New Residence</ng-container>
        </div>
    </div>
    <div>
        <ng-container *ngIf="item.values.alerts?.length">
            <button mat-icon-button [matMenuTriggerFor]="alerts">
                <mat-icon color="warn">{{GLOBAL.ICONS.ALERT}}</mat-icon>
            </button>
            <mat-menu #alerts="matMenu">
                <button mat-menu-item *ngFor="let alert of item.values.alerts">
                    <mat-icon color="warn" *ngIf="alert.type === 'error'">{{GLOBAL.ICONS.ERROR}}</mat-icon>
                    <mat-icon color="accent" *ngIf="alert.type === 'marketing'">{{GLOBAL.ICONS.MARKETING}}</mat-icon>
                    <span>{{alert.code | param:'alerts'}}</span>
                </button>
            </mat-menu>


        </ng-container>
        <profile></profile>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="isOneOfRoles(['admin', 'director', 'assistant' ])" (click)="handover()">
                <mat-icon>{{GLOBAL.ICONS.HANDOVER}}</mat-icon>
                <span>Swap Agent</span>
            </button>
            <button mat-menu-item (click)="logs()">
                <mat-icon>{{GLOBAL.ICONS.LOGS}}</mat-icon>
                <span>Logs</span>
            </button>
            <button mat-menu-item *ngIf="!new && !pageConfig.edit && isRole('admin')" color="basic"
                (click)="db.deleteItem(item)">
                <mat-icon matListIcon>delete</mat-icon>
                <span>Delete</span>
            </button>
        </mat-menu>
    </div>

</mat-toolbar>
<div class="toolbartop"></div>

<div class="mainPage">
    <img *ngIf="item.values.realtor" mat-card-avatar class="realtorPage" [src]="item.values.realtor | realtor"
        [matTooltip]="(item.values.realtor | realtorName) + '&#13;' + (item.values.agency | param:'agencies')" />

    <mat-tab-group [(selectedIndex)]="pageConfig.mainTab" class="residencePage" *ngIf="!pageConfig.edit">


        <!--------------------------------------------- OVERVIEW ----------------------------------------------------------->

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.DASHBOARD}}</mat-icon>&nbsp;
                Overview
            </ng-template>
            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell">
                        <mat-card class="full">
                            <mat-list *ngIf="pageConfig.loaded">
                                <mat-list-item>
                                    <mat-icon matListIcon>
                                        {{GLOBAL.ICONS.RESIDENCE}}</mat-icon>
                                    <h3 matLine> {{item.values.name}} </h3>
                                </mat-list-item>
                                <mat-list-item *ngIf="item.values.synaps?.promoterObj">
                                    <mat-icon matListIcon>
                                        {{GLOBAL.ICONS.CONTACT}}</mat-icon>
                                    <h3 matLine> {{item.values.synaps.promoterObj.displayname}} </h3>
                                </mat-list-item>
                                <mat-list-item>
                                    <mat-icon matListIcon>
                                        {{GLOBAL.ICONS.ADDRESS}}</mat-icon>
                                    <h3 matLine> {{item.values.address?.eCityName ? item.values.address.eCityName :
                                        "Missing
                                        Address"}} </h3>
                                </mat-list-item>
                                <mat-list-item>
                                    <mat-icon matListIcon>
                                        {{GLOBAL.ICONS.DATE}}</mat-icon>
                                    <h3 matLine>{{item.values.builtYear ? item.values.builtYear : 'Missing Delivery
                                        Year'}}
                                        - {{item.values.status | param:'residenceStatus'}}
                                    </h3>
                                </mat-list-item>
                                <mat-list-item
                                    *ngIf="item.values.types && (item.values.types.includes('apartment') || item.values.types.includes('office'))">
                                    <mat-icon matListIcon>
                                        {{GLOBAL.ICONS.TOPFLOOR}}</mat-icon>
                                    <h3 matLine>{{item.values.topFloor ? (item.values.topFloor | param:'unitFloors') :
                                        "Missing Top
                                        Floor"}}
                                    </h3>
                                </mat-list-item>
                                <mat-list-item>
                                    <mat-icon matListIcon>
                                        {{GLOBAL.ICONS.UNIT}}</mat-icon>
                                    <h3 matLine>{{units?.length}} /
                                        {{item.values.totalUnits ?
                                        item.values.totalUnits : "Missing Total Units"}}
                                    </h3>
                                </mat-list-item>
                            </mat-list>
                            <mat-list class="h">

                            </mat-list>
                            <mat-list class="h">

                            </mat-list>
                            <mat-list class="icons">
                                <!-- <mat-list-item>
                        <mat-icon matListIcon>{{fields.includeAC.icon}}</mat-icon>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-icon matListIcon>{{fields.params.sub.privatePool.icon}}</mat-icon>
                    </mat-list-item> -->
                            </mat-list>
                            <!-- <mat-list>
                    <mat-list-item class="cursor" *ngFor="let mandate of values.synaps.unitMandatesArray"
                        (click)="goItem('mandates', mandate)">
                        <mat-icon matListIcon>{{GLOBAL.ICONS.MANDATE}}</mat-icon>
                        <h3 matLine> NES{{mandate.ref}} {{mandate.dateStart.toDate() | date:'dd MMM yyyy'}} {{mandate.price | number}} {{mandate.cy | param:'cies'}}</h3>
                    </mat-list-item>
                </mat-list> -->
                        </mat-card>

                    </div>
                    <div class="mdc-layout-grid__cell--span-4">
                        <app-gps *ngIf="pageConfig.loaded" style="min-height: 250px; height: 100%;"
                            [values]="item.values">
                        </app-gps>
                    </div>
                    <div class="mdc-layout-grid__cell--span-4">
                        <slideshow [values]="item.values"></slideshow>
                    </div>



                    <div class="mdc-layout-grid__cell--span-3">
                        <form-builder [item]="item" [values]="item.values" section="params"
                            [edit]="false"></form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell--span-3">
                        <form-builder [item]="item" [values]="item.values" section="equipment"
                            [edit]="false"></form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell--span-3">
                        <form-builder [item]="item" [values]="item.values" section="security"
                            [edit]="false"></form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell--span-3">
                        <form-builder [item]="item" [values]="item.values" section="area" [edit]="false"></form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell--span-12" *ngIf="pageConfig.loaded">

                        <mat-card>
                            <mat-card-header>
                                <mat-card-title>Introduction</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <p>{{item.values.intro?.en}}</p>
                            </mat-card-content>
                        </mat-card>

                        <mat-card>
                            <mat-card-header>
                                <mat-card-title>Highlights</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <ul>
                                    <li *ngFor="let snap of item.values.snapshot | toarray:true">{{snap.text?.en}}</li>
                                </ul>
                            </mat-card-content>
                        </mat-card>

                        <mat-card *ngFor="let desc of item.values.descs | toarray:true">
                            <mat-card-header>
                                <mat-card-title>{{desc.title?.en}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <p>{{desc.text?.en}}</p>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
            <!-- EDIT -->
            <button *ngIf="!new && !pageConfig.edit" mat-fab class="bottom-right pfixed" (click)="editItem()">
                <mat-icon matListIcon>edit</mat-icon>
            </button>
        </mat-tab>

        <!--------------------------------------------- UNITS ----------------------------------------------------------->

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.UNITS}}</mat-icon>&nbsp;
                {{units?.length}} Properties
            </ng-template>

            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell--span-12" *ngIf="units && units.length">
                        <table mat-table [dataSource]="units" class="mat-elevation-z8">

                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef>UNITS</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-icon matListIcon [ngClass]="{active: element.active}">
                                        {{setting.getParamIcon('unitTypes', element.type)}}
                                    </mat-icon>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ref">
                                <th mat-header-cell *matHeaderCellDef> Ref </th>
                                <td mat-cell *matCellDef="let element"> P{{element.ref}} </td>
                            </ng-container>
                            <ng-container matColumnDef="unit">
                                <th mat-header-cell *matHeaderCellDef>Unit</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.unitNb}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="bedrooms">
                                <th mat-header-cell *matHeaderCellDef>Bedrooms</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.bedrooms}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="bathrooms">
                                <th mat-header-cell *matHeaderCellDef>Bathrooms</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.bathrooms}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="surface">
                                <th mat-header-cell *matHeaderCellDef>Surface</th>
                                <td mat-cell *matCellDef="let element">
                                    <!-- <ng-container *ngIf="element.type != 'land'"> -->
                                    {{element.surface ? element.surface + ' m²' : ''}}
                                    <!-- </ng-container> -->
                                    <!-- <ng-container *ngIf="element.type == 'land'"> {{element.landSurface}}
                    {{element.surface | param:'landUnits'}}
                </ng-container> -->
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="owners">
                                <th mat-header-cell *matHeaderCellDef> Owners </th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngFor="let contact of element.owners; let i = index"><br
                                            *ngIf="i" />{{contact}}
                                    </ng-container>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="mandates">
                                <th mat-header-cell *matHeaderCellDef> Mandates </th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngFor="let mandate of element.mandates; let i = index"><br
                                            *ngIf="i" />{{mandate}}
                                    </ng-container>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="realtors">
                                <th mat-header-cell *matHeaderCellDef> Agents </th>
                                <td mat-cell *matCellDef="let element">
                                    <img *ngIf="element.saleRealtor" mat-card-avatar
                                        [src]="element.saleRealtor | realtor"
                                        [matTooltip]="element.saleRealtor | realtorName" />
                                    <img *ngIf="element.rentalRealtor" mat-card-avatar
                                        [src]="element.rentalRealtor | realtor"
                                        [matTooltip]="element.rentalRealtor | realtorName" />
                                </td>
                            </ng-container>

                            <tr mat-header-row class="unitsbg"
                                *matHeaderRowDef="['type','unit', 'bedrooms', 'bathrooms', 'surface','owners','mandates','ref', 'realtors']">
                            </tr>
                            <tr mat-row
                                *matRowDef="let row; let element; columns: ['type','unit', 'bedrooms', 'bathrooms', 'surface','owners','mandates','ref', 'realtors'];"
                                (click)="goItem('units', element)" class="cursor">
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <!-- NEW UNIT -->
            <button *ngIf="!new && !pageConfig.edit" [matTooltipDisabled]="!item.values.error"
                matTooltip="Fix the Errors before Adding a Unit" [disabled]="item.values.error" color="primary" mat-fab
                class="bottom-right pfixed" (click)="addUnit()">
                <mat-icon matListIcon>{{GLOBAL.ICONS.UNITADD}}</mat-icon>
            </button>
        </mat-tab>

        <!--------------------------------------------- VEFAS/PROMOTER ----------------------------------------------------------->

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.UNITS}}</mat-icon>&nbsp;
                {{vefas?.length}} VEFAS/Promoter
            </ng-template>

            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell--span-12" *ngIf="vefas && vefas.length">
                        <table mat-table [dataSource]="vefas" class="mat-elevation-z8 vefas">
                            <ng-container matColumnDef="vefaName">
                                <th mat-header-cell *matHeaderCellDef>Name</th>
                                <td mat-cell *matCellDef="let element">{{element.vefaName}}</td>
                            </ng-container>
                            <ng-container matColumnDef="vefaNb">
                                <th mat-header-cell *matHeaderCellDef>Availability</th>
                                <td mat-cell *matCellDef="let element">{{element.vefaAv !== null ? element.vefaAv :
                                    '?'}}/{{element.vefaNb !== null ? element.vefaNb : '?'}}</td>
                            </ng-container>
                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef>VEFAS</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-icon matListIcon [ngClass]="{active: element.active}">
                                        {{setting.getParamIcon('unitTypes', element.type)}}
                                    </mat-icon>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ref">
                                <th mat-header-cell *matHeaderCellDef> Ref </th>
                                <td mat-cell *matCellDef="let element"> P{{element.ref}} </td>
                            </ng-container>
                            <ng-container matColumnDef="bedrooms">
                                <th mat-header-cell *matHeaderCellDef>Bedrooms</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.bedrooms}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="bathrooms">
                                <th mat-header-cell *matHeaderCellDef>Bathrooms</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.bathrooms}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="surface">
                                <th mat-header-cell *matHeaderCellDef>Surface</th>
                                <td mat-cell *matCellDef="let element">
                                    <!-- <ng-container *ngIf="element.type != 'land'"> -->
                                    {{element.surface ? element.surface + ' m²' : ''}}
                                    <!-- </ng-container> -->
                                    <!-- <ng-container *ngIf="element.type == 'land'"> {{element.landSurface}}
            {{element.surface | param:'landUnits'}}
        </ng-container> -->
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="mandates">
                                <th mat-header-cell *matHeaderCellDef>Mandate</th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngFor="let mandate of element.mandates; let i = index"><br
                                            *ngIf="i" />{{mandate}}
                                    </ng-container>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="realtors">
                                <th mat-header-cell *matHeaderCellDef> Agents </th>
                                <td mat-cell *matCellDef="let element">
                                    <img *ngIf="element.saleRealtor" mat-card-avatar
                                        [src]="element.saleRealtor | realtor"
                                        [matTooltip]="element.saleRealtor | realtorName" />
                                    <img *ngIf="element.rentalRealtor" mat-card-avatar
                                        [src]="element.rentalRealtor | realtor"
                                        [matTooltip]="element.rentalRealtor | realtorName" />
                                </td>
                            </ng-container>

                            <tr mat-header-row class="vefasbg"
                                *matHeaderRowDef="['type','vefaNb','vefaName', 'bedrooms', 'bathrooms', 'surface','mandates','ref', 'realtors']">
                            </tr>
                            <tr mat-row
                                *matRowDef="let row; let element; columns: ['type','vefaNb','vefaName', 'bedrooms', 'bathrooms', 'surface','mandates','ref', 'realtors'];"
                                (click)="goItem('units', element)" class="cursor">
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <!-- NEW UNIT -->
            <button *ngIf="!new && !pageConfig.edit" [matTooltipDisabled]="!item.values.error"
                matTooltip="Fix the Errors before Adding a Unit" [disabled]="item.values.error" color="primary" mat-fab
                class="bottom-right pfixed" (click)="addUnit()">
                <mat-icon matListIcon>{{GLOBAL.ICONS.UNITADD}}</mat-icon>
            </button>
        </mat-tab>

        <!--------------------------------------------- TIMELINE ----------------------------------------------------------->

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.SUMMARY}}</mat-icon>&nbsp;
                Timeline
            </ng-template>

            <div class="action" *ngFor="let action of actionService?.items">

                <div class="date">
                    <div class="txt">
                        {{action.date?.toDate() | date:'dd MMM yy'}}<br />
                        {{action.date?.toDate() | date:'HH:mm'}}
                    </div>
                    <div class="actionRealtor" (click)="copyClipboard(action.$key)">
                        <button mat-mini-fab [color]="action.done ? 'primary' : 'accent'" style="cursor:none">
                            <mat-icon>{{action.type | icon:'actionTypes'}}</mat-icon>
                        </button>
                        <img mat-card-avatar [src]="action.realtor | realtor"
                            [matTooltip]="action.realtor | realtorName" />
                    </div>
                </div>
                <div class="panel">
                    <mat-spinner [strokeWidth]="3" [diameter]="70" *ngIf="action.creating"></mat-spinner>
                    <action-select *ngIf="!action.creating" [action]="action" [moduleItem]="item"></action-select>
                </div>
            </div>
        </mat-tab>

        <!------------------- COMPANIES --------------------------->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>{{GLOBAL.ICONS.CONTACT}}</mat-icon>&nbsp;
                Contacts
            </ng-template>


            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell--span-4">
                        <mat-card>
                            <mat-card-header (click)="goItem('companies', item.values.synaps?.promoterObj)"
                                class="cursor">
                                <mat-card-title>
                                    <div
                                        style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                                        Promoter
                                        <button mat-icon-button (click)="select.init(item, db, 'promoter')">
                                            <mat-icon color="primary">sync_alt</mat-icon>
                                        </button>
                                    </div>
                                </mat-card-title>
                            </mat-card-header>
                        </mat-card>
                        <app-company-tab *ngIf="item.values.synaps?.promoterObj"
                            [key]="item.values.synaps?.promoterObj.$key" model="residences"
                            [modelKey]="item.values.$key"></app-company-tab>
                    </div>
                    <div class="mdc-layout-grid__cell--span-4">
                        <mat-card>
                            <mat-card-header (click)="goItem('companies', item.values.synaps?.syndicObj)"
                                class="cursor">
                                <mat-card-title>
                                    <div
                                        style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                                        Syndic
                                        <button mat-icon-button (click)="select.init(item, db, 'syndic')">
                                            <mat-icon color="primary">sync_alt</mat-icon>
                                        </button>
                                    </div>
                                </mat-card-title>
                            </mat-card-header>
                        </mat-card>
                        <app-company-tab *ngIf="item.values.synaps?.syndicObj"
                            [key]="item.values.synaps?.syndicObj.$key" model="residences"
                            [modelKey]="item.values.$key"></app-company-tab>
                    </div>
                    <div class="mdc-layout-grid__cell--span-4">
                        <mat-card>
                            <mat-card-header (click)="goItem('companies', item.values.synaps?.builderObj)"
                                class="cursor">
                                <mat-card-title>
                                    <div
                                        style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                                        Builder
                                        <button mat-icon-button (click)="select.init(item, db, 'builder')">
                                            <mat-icon color="primary">sync_alt</mat-icon>
                                        </button>
                                    </div>
                                </mat-card-title>
                            </mat-card-header>
                        </mat-card>
                        <app-company-tab *ngIf="item.values.synaps?.builderObj"
                            [key]="item.values.synaps?.builderObj.$key" model="residences"
                            [modelKey]="item.values.$key"></app-company-tab>

                    </div>
                </div>
            </div>


        </mat-tab>
    </mat-tab-group>


    <!------------------------------ EDIT MODE  ----------------------------------------->

    <div class="unitPage" *ngIf="pageConfig.edit">
        <mat-tab-group [(selectedIndex)]="pageConfig.editTab">

            <!-- PROPERTY DETAILS -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>{{GLOBAL.ICONS.DETAILS}}</mat-icon>&nbsp;
                    Details
                </ng-template>

                <div class="mdc-layout-grid">
                    <div class="mdc-layout-grid__inner">
                        <div class="mdc-layout-grid__cell">
                            <form-builder [item]="item" [values]="item.values" section="main"
                                [edit]="true"></form-builder>
                        </div>
                    </div>
                </div>

                <!-- EDIT MODE -->
                <button *ngIf="!new && pageConfig.edit" mat-fab class="bottom-right pfixed"
                    [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
                    <mat-icon matListIcon>check</mat-icon>
                </button>
                <!-- CANCEL -->
                <button mat-fab class="bottom-right pfixed p2" [disabled]="!item.modified || item.saving" color="basic"
                    (click)="cancelEdit()">
                    <mat-icon matListIcon>close</mat-icon>
                </button>
            </mat-tab>


            <!-- ADDRESS / GPS -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>{{GLOBAL.ICONS.ADDRESS}}</mat-icon>&nbsp;
                    Address
                </ng-template>

                <div class="mdc-layout-grid">
                    <div class="mdc-layout-grid__inner">
                        <div style="height: 500px;" class="mdc-layout-grid__cell--span-8">
                            <app-gps [edit]="true" [control]="true" [values]="item.values"
                                (modify)="saveAddress($event)">
                            </app-gps>
                        </div>
                        <div class="mdc-layout-grid__cell">
                            <form-builder [item]="item" [values]="item.values" section="address" [edit]="true">
                            </form-builder>
                        </div>
                    </div>
                </div>

                <!-- EDIT MODE -->
                <button *ngIf="!new && pageConfig.edit" mat-fab class="bottom-right pfixed"
                    [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
                    <mat-icon matListIcon>check</mat-icon>
                </button>
                <!-- <button *ngIf="!new && pageConfig.edit" mat-fab class="bottom-right pfixed p2" color="basic"
                (click)="cancelEdit()">
                <mat-icon matListIcon>close</mat-icon>
            </button> -->
            </mat-tab>


            <!-- PROPERTY DETAILS -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>{{GLOBAL.ICONS.PARAM}}</mat-icon>&nbsp;
                    Parameters
                </ng-template>

                <div class="mdc-layout-grid">
                    <div class="mdc-layout-grid__inner">
                        <div class="mdc-layout-grid__cell--span-3">
                            <form-builder [item]="item" [values]="item.values" section="params" [edit]="true">
                            </form-builder>
                        </div>
                        <div class="mdc-layout-grid__cell--span-3">
                            <form-builder [item]="item" [values]="item.values" section="equipment" [edit]="true">
                            </form-builder>
                        </div>
                        <div class="mdc-layout-grid__cell--span-3">
                            <form-builder [item]="item" [values]="item.values" section="security" [edit]="true">
                            </form-builder>
                        </div>
                        <div class="mdc-layout-grid__cell--span-3">
                            <form-builder [item]="item" [values]="item.values" section="area"
                                [edit]="true"></form-builder>
                        </div>
                    </div>
                </div>

                <!-- EDIT MODE -->
                <button *ngIf="!new && pageConfig.edit" mat-fab class="bottom-right pfixed"
                    [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
                    <mat-icon matListIcon>check</mat-icon>
                </button>
                <!-- CANCEL -->
                <button mat-fab class="bottom-right pfixed p2" [disabled]="!item.modified || item.saving" color="basic"
                    (click)="cancelEdit()">
                    <mat-icon matListIcon>close</mat-icon>
                </button>
            </mat-tab>

            <!-- DESCRIPTIONS -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>{{GLOBAL.ICONS.DESC}}</mat-icon>&nbsp;
                    Description
                </ng-template>

                <div class="mdc-layout-grid">
                    <div class="mdc-layout-grid__inner">
                        <div class="mdc-layout-grid__cell--span-12">
                            <form-builder [item]="item" [values]="item.values" section="desc"
                                [edit]="true"></form-builder>
                        </div>
                    </div>
                </div>

                <!-- EDIT MODE -->
                <button *ngIf="!new && pageConfig.edit" mat-fab class="bottom-right pfixed"
                    [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
                    <mat-icon matListIcon>check</mat-icon>
                </button>
                <!-- CANCEL -->
                <button mat-fab class="bottom-right pfixed p2" [disabled]="!item.modified || item.saving" color="basic"
                    (click)="cancelEdit()">
                    <mat-icon matListIcon>close</mat-icon>
                </button>
                <!-- TRANSLATE -->
                <button mat-fab class="bottom-right pfixed p3" [disabled]="item.modified || item.saving" color="primary"
                    (click)="translate()">
                    <mat-icon matListIcon>g_translate</mat-icon>
                </button>
            </mat-tab>

            <!-- PHOTOS -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>{{GLOBAL.ICONS.PHOTOS}}</mat-icon>&nbsp;
                    {{item.values.photos?.length}} Photos
                </ng-template>
                <upload-images [fileRecords]="item.values.photos" [config]="imageConf" (action)="actionPhoto($event)">
                </upload-images>
            </mat-tab>



        </mat-tab-group>
    </div>
</div>
<mat-spinner [strokeWidth]="5" class="fixed" *ngIf="!(pageConfig.loaded || new)"></mat-spinner>

<!-- NEW -->
<button *ngIf="new" mat-fab class="bottom-right pfixed" [disabled]="!item.valid || !item.modified || item.saving"
    (click)="saveNewItem()">
    <mat-icon matListIcon>check</mat-icon>
</button>