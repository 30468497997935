import { Component, Input, OnInit } from "@angular/core";
import { Matching } from "../../services/matching/matching.model";
import { MatchingService } from "../../services/matching/matching.service";
import { EventEmitter } from "@angular/core";
import { Output } from "@angular/core";
import { GlobalConstants } from "../../../functions/src/shared/global-constants";
import { Router } from "@angular/router";
import { BackurlService } from "src/common/services/backurl/backurl.service";
import { Project } from "src/services/project/project.model";

@Component({
    selector: 'new-matchings',
    templateUrl: './new-matchings.component.html',
})
export class NewMatchingsComponent implements OnInit {

    public GLOBAL = new GlobalConstants();
    public matchingItem = new Matching();
    public matchTab: number = 0;
    public preview: any = null;

    public notNewMatch: { [matchKey: string]: boolean } = {}; // In order to make it more userfriendly

    @Input()
    matching: MatchingService;

    @Input()
    public project: Project;

    @Output()
    doneFn: EventEmitter<any> = new EventEmitter<any>();

    tableCols = ['icon', 'ref', 'city', 'type', 'bedrooms', 'bathrooms', 'owners', 'price', 'yesno'];

    constructor(
        public backURL: BackurlService,
        protected router: Router,
    ) {

    }

    ngOnInit(): void {
        // this.matching.setLocalFilter({ matchOnly: true } );
    }

    overMatching(match: any) {
        if (!(this.preview && match && match.mandate && match.mandate.$key === this.preview.$key)) {
            this.preview = null;
            setTimeout(() => {
                this.preview = match.mandate;
            }, 50);
        }
    }

    clickMatching(match: any) {
        if (this.matchTab !== 0) {
            this.matchTab = 0;
            return;
          }
              
        this.backURL.addURL(`/projects/${match.project.$key}`);
        this.router.navigate([`/mandates/${match.mandate.$key}`]);
    }

    match(matching: any) {
        this.matching.match(matching);
        this.notNewMatch[matching.$key] = true;
        this.notNewMatch = Object.assign({}, this.notNewMatch);
        this._checkIfLast();
    }

    unmatch(matching: any) {
        this.matching.unmatch(matching);
        this.notNewMatch[matching.$key] = true;
        this.notNewMatch = Object.assign({}, this.notNewMatch);
        this._checkIfLast();
    }

    private _checkIfLast() {
        if (this.matching.itemsFiltered && this.matching.itemsFiltered.length === 1) {
            this.doneFn.emit();
        }
    }

}