import { ChangeDetectorRef, Component, Inject, Input, OnInit } from "@angular/core";
import { Matching } from "../../services/matching/matching.model";
import { MatchingService } from "../../services/matching/matching.service";
import { EventEmitter } from "@angular/core";
import { Output } from "@angular/core";
import { SelectService } from "../../common/services/select/select.service";
import { FormBuilder } from "@angular/forms";
import { GlobalConstants } from "../../../functions/src/shared/global-constants";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from "@angular/router";
import { MainComponent } from "../../common/pages/main/main.component";
import { SettingService } from "../../common/services/setting/setting.service";
import { BackurlService } from "src/common/services/backurl/backurl.service";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { ActionService } from "src/services/action/action.service";
import { Project } from "src/services/project/project.model";
import { Action } from "src/services/action/action.model";
import { ActionOffer } from "src/services/action/actions/action-offer.model";
import { ActionSignLease } from "src/services/action/actions/action-sign-lease.model";
import { MandateService } from "src/services/mandate/mandate.service";
import { Mandate } from "src/services/mandate/mandate.model";
import { ActionSignAgree } from "src/services/action/actions/action-sign-agree.model";
import { ActionSignDeed } from "src/services/action/actions/action-sign-deed.model";
import { matchings } from "functions/src/shared/list";

@Component({
  selector: 'mandateMatchings',
  templateUrl: './mandate-matchings.component.html',
})
export class MandateMatchingsComponent implements OnInit {

  public GLOBAL = new GlobalConstants();
  public matchTab: number = 0;
  public matchingItem = new Matching();
  public preview: any = null;
  public tableCols: string[] = [];
  private _select: boolean;
  sortMatchingLocalForm = this.fb.group({
    matchOnly: [true],
    newOnly: [false],
    visit: [false],
  });

  @Input()
  public project: Project;

  @Input()
  matching: MatchingService;

  @Input()
  matchOnly: boolean;

  @Input()
  status: string;

  @Input()
  set matchSelect(select: boolean) {
    this._select = select;
    if (select) {
      this.tableCols = ['select', 'icon', 'ref', 'city', 'type', 'bedrooms', 'bathrooms', 'price', 'actions'];
    } else {
      this.tableCols = ['icon', 'ref', 'city', 'type', 'bedrooms', 'bathrooms', 'price', 'actions', 'desc', 'match'];
    }
  }
  get matchselect() {
    return this._select;
  }

  @Output() doneFn: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelFn: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    protected _cd: ChangeDetectorRef,
    public select: SelectService,
    protected fb: FormBuilder,
    protected afs: AngularFirestore,
    public dialog: MatDialog,
    public backURL: BackurlService,
    protected router: Router,
  ) {

  }

  ngOnInit(): void {
    this.sortMatchingLocalForm.setValue(this.matching.localFilters);
    this.sortMatchingLocalForm.valueChanges.pipe(debounceTime(500)).subscribe(filter => {
      setTimeout(() => {
        this.matching.setLocalFilter(filter);
        this._cd.detectChanges();
      }, 10);
    });
  }


  overMatching(match: any) {
    if (!(this.preview && match && match.mandate && match.mandate.$key === this.preview.$key)) {
      this.preview = null;
      setTimeout(() => {
        this.preview = match.mandate;
      }, 50);
    }
  }

  clickMatching(match: any) {
    this.backURL.addURL(`/projects/${match.project.$key}`);
    this.router.navigate([`/mandates/${match.mandate.$key}`]);
  }

  openWebsite(match: any, event: MouseEvent) {
    window.open(match.mandate.webURLs?.en, '_blank');
    event.stopPropagation();
  }


  done() {
    this.doneFn.emit();
  }

  cancel() {
    this.cancelFn.emit();
  }

  edit(matching: any, event: MouseEvent) {
    event.stopPropagation();

    if (this.matchTab !== 0) {
      this.matchTab = 0;
      return;
    }
    
    const dialogRef = this.dialog.open(EditMatchingDialogComponent, {
      width: '800px',
      disableClose: true,
      data: { matchingKey: matching.$key, status: this.status, project: this.project }
    });

    dialogRef.afterClosed().subscribe(newDate => {

    });

  }

  copyLinks() {
    let copy = '';
    for (const match of this.matching.itemsFiltered) {
      let lang = 'en';
      if (this.project.values.synaps.contactsProjectsArray?.length && this.project.values.synaps.contactsProjectsArray[0].lang) {
        lang = this.project.values.synaps.contactsProjectsArray[0].lang;
      }
      let cy = 'mur';
      if (this.project.values.cyBudget) {
        cy = this.project.values.cyBudget;
      }
      if (match.mandate.webURLs[lang]) {
        copy += match.mandate.webURLs[lang] + `?cy=${cy}` + '\n';
      }
    }
    navigator.clipboard.writeText(copy);
  }

}


@Component({
  selector: 'edit-matching-dialog',
  templateUrl: './edit-matching-dialog.component.html',
})
export class EditMatchingDialogComponent extends MainComponent {


  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: MatchingService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialogRef: MatDialogRef<EditMatchingDialogComponent>,
    public backURL: BackurlService,
    public actionService: ActionService,
    public mandateService: MandateService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Matching(null, setting);
  }


  ngOnInit(): void {

    if (this.data && this.data.matchingKey) {
      this._$key = this.data.matchingKey;

      this.new = false;

      this.db.initItem(this);
      this._cd.markForCheck();

      this._bindItem().then(() => {
        this.pageConfig.loaded = true;
        this._itemOnInit();
      });
    }

  }


  async createActionSign(actionType: string) {

    let itemClass = Action;
    if (actionType === 'offer') {
      itemClass = ActionOffer;
    } else if (actionType === 'signLease') {
      itemClass = ActionSignLease;
    } else if (actionType === 'signAgree') {
      itemClass = ActionSignAgree;
    } else if (actionType === 'signDeed') {
      itemClass = ActionSignDeed;
    }

    if (this.data && this.data.project && this.item.values.synaps.mandateMatchings) {
      //Get Mandate data first
      const newData = {
        data: {},
        mandateFee: {},
        projectFee: {},
        realtor: this.data.project.values.realtor,
        agency: this.data.project.values.agency,
      };
      await this._getMandateDetails(newData);

      this.actionService.createAction(
        itemClass,
        actionType,
        [
          { item: this.data.project },
          { item: this.item, synap: actionType },
          { synapObj: { mandatesActions: this.item.values.synaps.mandateMatchings } }
        ],
        { data: newData } // Options
      ).then((actionKey: string) => {
        this.backURL.addURL(`/projects/${this.data.project.values.$key}`);
        this.router.navigate([`/tasks/${actionKey}`]);
      });
    }
    this.save();
  }

  async _getMandateDetails(newData: any): Promise<any> {

    const mandate = new Mandate(null, this.setting);

    if (this.item.values.synaps && this.item.values.synaps.mandateMatchings, Object.keys(this.item.values.synaps.mandateMatchings).length) {

      const mandateKey = Object.keys(this.item.values.synaps.mandateMatchings)[0];

      return this.mandateService.getData(mandateKey).then(data => {
        mandate.fromFB(data);
        if (mandate.values.agencyFee) {
          newData.data.price = mandate.values.price;
          newData.data.cy = mandate.values.cy;
          if (mandate.values.agencyFee && mandate.values.agencyFee.type) {
            Object.assign(newData.mandateFee, mandate.values.agencyFee);
            //Select Project Agency Fee according the mandate
            if (mandate.values.noAgencyFee) {
              newData.projectFee.type = 'no';
            } else {
              newData.projectFee.type = 'std';
            }
          }
        }
      });
    }
  }


  match(matching: boolean) {
    this.item.modifyData('match', matching);
    this.db.saveMatch(matchings(this.item.values), matching);
    if (!matching) {
      this.dialogRef.close();
    }
  }

  save() {
    this.saveItem();
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  goMandate() {
    this.dialogRef.close();
    this.backURL.addURL(`/projects/${this.item.values.synaps.projectMatchingsObj.$key}`);
    this.router.navigate([`/mandates/${this.item.values.synaps.mandateMatchingsObj.$key}`]);
  }
}
