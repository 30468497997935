
import { checkStatusActive } from 'functions/src/shared/tools';
import { MainItem, Field, Alert, AgencyFee, AgencyFeeFields } from '../../common/services/main/main.model';
import { SettingService } from '../../common/services/setting/setting.service';
import { FileRecord } from '../../common/services/upload-file/upload-file-model';

export class Project extends MainItem {

    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],

        synaps: any,

        actionUpdated: Date,

        type: string,
        status: string,
        origin: string,
        originMedium: string,
        originNote: string,
        temperature: string,
        realtor: string,
        agency: string,
        referral: string,
        referralAgency: string,
        referralCom: number,
        broker: string,

        // dateValidReq: Date,
        // dateValidDisco: Date,
        // dateValidMatch: Date,
        // dateStart: Date, 

        // Perso
        nbChild: number,
        childAges: string,
        education: string,
        hobbyDesc: string,
        pet: boolean,
        petDesc: string,

        // Actual Home
        actualPlaceType: string,
        actualAddress: string,
        actualAdvantages: string,
        actualInconvenients: string,
        actualRent: number,

        // Motivations
        // summary: string,
        dateNeed: Date,
        // alternative: string,

        // Sector
        car: boolean,
        nearBus: boolean,
        nearSea: boolean,
        beachfront: boolean,
        seaView: boolean,
        nearCommerces: boolean,
        nearSchool: boolean,
        nearOffice: boolean,
        sectors: string[],

        // Unit Criterias
        foreigner: boolean,
        residentPermit: boolean,
        conditions: string[],
        unitTypes: string[],
        residence: boolean,
        params: { [id: string]: string | boolean },
        minBedrooms: number,
        maxBedrooms: number,
        minBathrooms: number,
        minSurface: number,
        maxSurface: number,
        priorityCriteria: string,
        secondaryCriteria: string,
        prohibitiveCriteria: string,

        // Budget
        minBudget: number,
        maxBudget: number,
        cyBudget: string,

        // Accounting
        agencyFee: AgencyFee,
        // Previous Experience 
        // pastExperience: boolean,
        // otherAgencies: boolean,
        // c2c: boolean,
        // visitedUnits: { unitDesc: string, result: string }[],


        // Failed
        dateEnd: Date,
        failedReason: string,
        failedNote: string,

        // DOCS
        docs: FileRecord[],

        //AUTO
        matching: boolean,
        matchingResults: any,
    }

    constructor(
        values: any = null,
        public setting?: SettingService,
    ) {
        super(
            'projects',
            {
                actionUpdated: new Field({
                    input: 'date',
                }),
                type: new Field({
                    label: 'Type', input: 'select', inputOptions: { param: 'projectTypes' },
                    validations: { required: true }
                }),
                status: new Field({ label: 'Status' }),

                origin: new Field({
                    label: 'Origin', input: 'select', inputOptions: { param: 'projectOrigins' },
                    validations: { required: true },
                }),
                originMedium: new Field({
                    label: 'Origin Medium', input: 'select', inputOptions: { param: 'mediums' },
                    validations: { required: true },
                }),
                originNote: new Field({
                    label: 'Origin Note', input: 'textarea',
                    validations: { required: true },
                }),
                temperature: new Field({
                    label: 'Temperature', input: 'select', inputOptions: { param: 'temperatures' },
                    validations: { required: true }
                }),
                realtor: new Field({
                    label: 'Agent', input: 'select', inputOptions: { param: 'realtors' },
                    validations: { required: true },
                    show: (data, subData, setting) => {
                        return ['admin', 'director', 'assistant', 'marketing'].includes(setting.auth.profile.values.role)
                    }
                }),
                agency: new Field({
                    label: 'Agency', input: 'select', inputOptions: { param: 'tmp' },
                    validations: { required: true },
                    show: (data, subData, setting) => {
                        if (setting.show === false) {
                            setting.show = true;
                            return false;
                        }
                        return data.realtor && setting && setting.users && setting.users[data.realtor] && setting.users[data.realtor].agencies && setting.users[data.realtor].agencies.length > 1
                    }
                    // input: 'auto',
                    // autoFn: {
                    //     toFB: (values, setting) => { return this._getAgency(setting, values.realtor) }
                    // }
                }),
                // dateStart: new Field({
                //     label: 'Start of project', input: 'date', default: new Date(),
                //     validations: { required: true }
                // }),
                dateNeed: new Field({
                    label: 'Moving Date', input: 'date',
                    validations: { required: true }
                }),
                foreigner: new Field({
                    input: 'boolean', label: 'Foreigner', default: false,
                    show: (data) => { return data && data.type === 'sale' },
                }),
                residentPermit: new Field({
                    input: 'boolean',
                    label: 'Need Resident Permit',
                    show: (data) => { return data && data.type == 'sale' && data.foreigner },
                }),
                conditions: new Field({
                    label: 'Property Conditions',
                    input: 'select', inputOptions: { param: 'unitConditionsVEFA', multi: true },
                    show: (data) => { return data && data.type == 'sale' },
                }),

                nbChild: new Field({ input: 'number', label: 'Nb of Children', }),
                childAges: new Field({ label: 'Age(s)', }),
                education: new Field({ label: 'Education', }),
                hobbyDesc: new Field({ input: 'textarea', label: 'Passion / Leisure', }),
                pet: new Field({ input: 'boolean', label: 'Pet(s)' }),
                petDesc: new Field({
                    label: 'Pet(s) Description',
                    show: (data) => { return data && data.pet },
                }),


                actualPlaceType: new Field({ label: 'Place Type', input: 'select', inputOptions: { param: 'actualPlaceTypes' } }),
                actualAddress: new Field({ label: 'Address', }),
                actualAdvantages: new Field({
                    label: 'Advantage',
                    show: (data) => { return (data.actualPlaceType != 'abroad' && data.actualPlaceType != 'hotel') },
                }),
                actualInconvenients: new Field({
                    label: 'Inconvenients',
                    show: (data) => { return (data.actualPlaceType != 'abroad' && data.actualPlaceType != 'hotel') },
                }),
                actualRent: new Field({
                    label: 'Rent (Rs)',
                    show: (data) => { return (data.actualPlaceType != 'abroad' && data.actualPlaceType != 'hotel') },
                }),

                // summary: new Field({ input: 'textarea' }),

                car: new Field({ input: 'boolean', label: 'Car', }),
                nearBus: new Field({ input: 'boolean', label: 'Near Bus Stop', }),
                nearSea: new Field({ input: 'boolean', label: 'Near Sea Front', }),
                beachfront: new Field({ input: 'boolean', label: 'Beachfront', }),
                seaView: new Field({ input: 'boolean', label: 'Sea View', }),
                nearCommerces: new Field({ input: 'boolean', label: 'Near Commerce', }),
                nearSchool: new Field({ input: 'boolean', label: 'Near School', }),

                nearOther: new Field({ label: 'Others', }),
                sectors: new Field({ input: "arrayValue", }),

                unitTypes: new Field({
                    label: 'Property Types',
                    input: 'select', inputOptions: { param: 'unitTypes', multi: true, icon: true },
                    required: () => { return true },
                }),
                residence: new Field({
                    input: 'boolean',
                    label: 'Inside Residence?',
                }),
                minBedrooms: new Field({
                    input: 'number', label: 'Minimum Bedrooms',
                    show: (data: any) => { return data && data.unitTypes && (data.unitTypes.includes('apartment') || data.unitTypes.includes('house')) },

                }),
                maxBedrooms: new Field({
                    input: 'number', label: 'Maximum Bedrooms',
                    show: (data: any) => { return data && data.unitTypes && (data.unitTypes.includes('apartment') || data.unitTypes.includes('house')) },

                }),
                minBathrooms: new Field({
                    input: 'number', label: 'Minimum Bathrooms',
                    show: (data: any) => { return data && data.unitTypes && (data.unitTypes.includes('apartment') || data.unitTypes.includes('house')) },
                }),
                minSurface: new Field({
                    input: 'number', label: 'Minimum Surface (m²)',
                    show: (data: any) => { return data && data.type == 'sale' || (data.unitTypes && (data.unitTypes.includes('office') || data.unitTypes.includes('commercial'))) },
                }),
                maxSurface: new Field({
                    input: 'number', label: 'Maximum Surface (m²)',
                    show: (data: any) => { return data && data.type == 'sale' || (data.unitTypes && (data.unitTypes.includes('office') || data.unitTypes.includes('commercial'))) },
                }),
                params: new Field({
                    label: 'Parameters',
                    input: 'objectSub',
                    show: (data: any) => {
                        return !(data && data.unitTypes && data.unitTypes.length == 1 && data.unitTypes.includes('land'))
                    },
                    sub: {
                        furnished: new Field({
                            input: 'boolean', label: 'Furnished',
                            show: (data: any) => { return data.type === 'rental' },
                        }),
                        semifurnished: new Field({
                            input: 'boolean', label: 'Semi-Furnished',
                            show: (data: any) => { return data.type === 'rental' },
                        }),
                        unfurnished: new Field({
                            input: 'boolean', label: 'Unfurnished',
                            show: (data: any) => { return data.type === 'rental' },
                        }),
                        groundFloor: new Field({
                            input: 'boolean', label: 'Ground Floor',
                            show: (data: any) => {
                                return (data && data.unitTypes && data.unitTypes.includes('apartment'))
                            },
                        }),
                        singleStorey: new Field({ input: 'boolean', label: 'Single-Storey', }),
                        pool: new Field({ input: 'boolean', label: 'Pool', }),
                        privatePool: new Field({
                            input: 'boolean', label: 'Private Pool',
                            show: (data: any) => { return data && data.params && data.params.pool },
                        }),
                        garden: new Field({
                            input: 'boolean', label: 'Garden',
                        }),
                        ac: new Field({
                            input: 'boolean', label: 'A/C',
                        }),
                    }
                }),
                cyBudget: new Field({
                    label: 'Currency',
                    input: 'select', inputOptions: { param: 'cies' },
                    validations: { required: true },
                    default: 'mur',

                }),
                minBudget: new Field({ input: 'number', label: 'Minimum Budget' }),
                maxBudget: new Field({
                    input: 'number', label: 'Maximum Budget',
                    validations: { required: true },

                }),
                dateEnd: new Field({
                    label: 'Date End', input: 'date',
                }),
                failedReason: new Field(),
                failedNote: new Field(),
                agencyFee: new Field({
                    label: 'Project\'s Agency Fee',
                    input: 'objectSub',
                    sub: new AgencyFeeFields(),
                }),
                referral: new Field({
                    label: 'Referral Agent', input: 'select', inputOptions: { param: 'users' },
                }),
                referralAgency: new Field({
                    label: 'Referral Agency', input: 'auto',
                    autoFn: {
                        toFB: (values, setting) => { return setting.getAgency(values.referral); }
                    },
                }),
                referralCom: new Field({
                    input: 'number', label: 'Referral (%)',
                    default: 25,
                    show: (data: any) => data && data.referral,
                }),
                matching: new Field({ input: 'boolean', default: false }),
                matchingResults: new Field({ input: 'object' }),
            },
            // Forms
            {
                // main: {
                //     title: null,
                //     fields: ['type', 'origin', 'originMedium', 'unitTypes', 'maxBudget', 'cyBudget', 'dateStart', 'dateNeed', 'temperature']
                // },
                newRealtor: {
                    title: null,
                    fields: ['type', 'origin', 'originNote', 'realtor', 'agency', 'referral', 'referralCom']
                },
                accounting: {
                    title: null,
                    fields: ['agencyFee', 'referral', 'referralCom']
                },
                // details: {
                //     title: null,
                //     fields: ['dateStart', 'dateNeed', 'temperature']
                // },
                unit: {
                    title: null,
                    fields: ['dateNeed', 'foreigner', 'residentPermit', 'unitTypes', 'conditions', 'minBedrooms', 'maxBedrooms', 'minBathrooms', 'minBudget', 'maxBudget', 'cyBudget', 'referral', 'referralCom']
                },
                perso: {
                    title: null,
                    fields: ['nbChild', 'childAges', 'education', 'hobbyDesc']
                },
                current: {
                    title: null,
                    fields: ['actualPlaceType', 'actualAddress', 'actualAdvantages', 'actualInconvenients', 'actualRent']
                },
                criteria: {
                    title: null,
                    fields: ['residence', 'beachfront', 'seaView', 'params', 'pet', 'petDesc']
                },
            },
            // Tables
            {
                main: {
                    title: 'Project List',
                    columns: [
                        { field: 'icon', type: 'icon', label: '' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'C' } },
                        { field: 'contactsProjects', type: 'string', label: 'Clients', options: { multi: true } },
                        // { field: 'type', type: 'string', label: 'Type' },
                        { field: 'unitTypeIcons', type: 'icon', label: 'Unit Types', options: { multi: true } },
                        { field: 'minBedrooms', type: 'number', label: 'Min Beds' },
                        { field: 'budget', type: 'string', label: 'Budget' },
                        { field: 'dateNeed', type: 'days', label: 'Days' },
                        { field: 'matchingsDisplay', type: 'string', label: 'Matchings' },

                    ],
                },
                realtor: {
                    title: 'Project List',
                    columns: [
                        { field: 'icon', type: 'icon', label: '' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'C' } },
                        { field: 'contactsProjects', type: 'string', label: 'Clients', options: { multi: true } },
                        // { field: 'type', type: 'string', label: 'Type' },
                        { field: 'unitTypeIcons', type: 'icon', label: 'Unit Types', options: { multi: true } },
                        { field: 'minBedrooms', type: 'number', label: 'Min Beds' },
                        { field: 'budget', type: 'string', label: 'Budget' },
                        { field: 'dateNeed', type: 'days', label: 'Days' },
                        { field: 'matchingsDisplay', type: 'string', label: 'Matchings' },
                        { field: 'realtor', type: 'user', label: 'Agent' },
                    ],
                },
                referral: {
                    title: 'Project List',
                    columns: [
                        { field: 'icon', type: 'icon', label: '' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'C' } },
                        { field: 'contactsProjects', type: 'string', label: 'Clients', options: { multi: true } },
                        // { field: 'type', type: 'string', label: 'Type' },
                        { field: 'unitTypeIcons', type: 'icon', label: 'Unit Types', options: { multi: true } },
                        { field: 'minBedrooms', type: 'number', label: 'Min Beds' },
                        { field: 'budget', type: 'string', label: 'Budget' },
                        { field: 'dateNeed', type: 'days', label: 'Days' },
                        { field: 'referralCom', type: 'number', label: 'Referral Com', options: { prefix: '%' } },
                        { field: 'realtor', type: 'user', label: 'Agent' },
                    ],
                },
                referralRealtor: {
                    title: 'Project List',
                    columns: [
                        { field: 'icon', type: 'icon', label: '' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'C' } },
                        { field: 'contactsProjects', type: 'string', label: 'Clients', options: { multi: true } },
                        // { field: 'type', type: 'string', label: 'Type' },
                        { field: 'unitTypeIcons', type: 'icon', label: 'Unit Types', options: { multi: true } },
                        { field: 'minBedrooms', type: 'number', label: 'Min Beds' },
                        { field: 'budget', type: 'string', label: 'Budget' },
                        { field: 'dateNeed', type: 'days', label: 'Days' },
                        { field: 'referral', type: 'user', label: 'Referral Agent' },
                        { field: 'referralCom', type: 'number', label: 'Referral Com', options: { suffix: '%' } },
                        { field: 'realtor', type: 'user', label: 'Agent' },
                    ],
                },
            },
            // Config File
            {
                path: 'projects/', filename: (data) => {
                    let filename = 'mandate-';
                    filename += 'NES' + data.ref;
                    return filename;
                }
            },
            setting,
        );
        if (values) {
            this.fromFB(values);
        }

    }

    _fnGetAutoData() {
        checkStatusActive(this.values);
    }


}