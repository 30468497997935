import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-matching-report',
  templateUrl: './matching-report.component.html',
  styleUrls: ['./matching-report.component.scss']
})
export class MatchingReportComponent {

  results: any;

  @Input() set matchingResults(results: any) {
    if (results) {
      this.results = results;
    }
    else {
      this.results = {};
    }
  }

  constructor() { }

}
