<mat-card>
    <mat-card-header>
        <mat-card-title>Not Matching Reasons</mat-card-title>
    </mat-card-header>
    <mat-list-item *ngIf="results.sectors">
        <div class="list-content">
            <div class="label">Sector:</div>
            <div class="value">{{results.sectors}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.residence">
        <div class="list-content">
            <div class="label">Residence:</div>
            <div class="value">{{results.residence}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.unitTypes">
        <div class="list-content">
            <div class="label">Unit Types:</div>
            <div class="value">{{results.unitTypes}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.noPrice">
        <div class="list-content">
            <div class="label">No Price:</div>
            <div class="value">{{results.noPrice}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.maxBudget">
        <div class="list-content">
            <div class="label">Max Budget:</div>
            <div class="value">{{results.maxBudget}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.minBudget">
        <div class="list-content">
            <div class="label">Min Budget:</div>
            <div class="value">{{results.minBudget}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.minBudget50">
        <div class="list-content">
            <div class="label">Min Budget 50%:</div>
            <div class="value">{{results.minBudget50}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.conditions">
        <div class="list-content">
            <div class="label">Conditions:</div>
            <div class="value">{{results.conditions}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.minBedrooms">
        <div class="list-content">
            <div class="label">Min Bedrooms:</div>
            <div class="value">{{results.minBedrooms}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.maxBedrooms">
        <div class="list-content">
            <div class="label">Max Bedrooms:</div>
            <div class="value">{{results.maxBedrooms}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.minBathrooms">
        <div class="list-content">
            <div class="label">Min Bathrooms:</div>
            <div class="value">{{results.minBathrooms}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.privatePool">
        <div class="list-content">
            <div class="label">Private Pool:</div>
            <div class="value">{{results.privatePool}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.commonPool">
        <div class="list-content">
            <div class="label">Common Pool:</div>
            <div class="value">{{results.commonPool}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.furniture">
        <div class="list-content">
            <div class="label">Furniture:</div>
            <div class="value">{{results.furniture}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.ac">
        <div class="list-content">
            <div class="label">AC:</div>
            <div class="value">{{results.ac}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.garden">
        <div class="list-content">
            <div class="label">Garden:</div>
            <div class="value">{{results.garden}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.groundFloor">
        <div class="list-content">
            <div class="label">Ground Floor:</div>
            <div class="value">{{results.groundFloor}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.singleStorey">
        <div class="list-content">
            <div class="label">Single Storey:</div>
            <div class="value">{{results.singleStorey}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.beachfront">
        <div class="list-content">
            <div class="label">Beachfront:</div>
            <div class="value">{{results.beachfront}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.seaView">
        <div class="list-content">
            <div class="label">Sea View:</div>
            <div class="value">{{results.seaView}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.petAllowed">
        <div class="list-content">
            <div class="label">Pet Allowed:</div>
            <div class="value">{{results.petAllowed}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.foreigner">
        <div class="list-content">
            <div class="label">Foreigner:</div>
            <div class="value">{{results.foreigner}}</div>
        </div>
    </mat-list-item>
    <mat-list-item *ngIf="results.residentPermit">
        <div class="list-content">
            <div class="label">Resident Permit:</div>
            <div class="value">{{results.residentPermit}}</div>
        </div>
    </mat-list-item>
</mat-card>